
import { defineComponent, Ref, ref, watch } from "vue";
import Api from "@/services/Api";
import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import useEmitter from "@/composables/Emmiter";


interface dashboardFilter {
  idLojas: string;
  idGrupos: string;
  data: any;
  regiao: any;
}

interface ContentDashboard {
  consultas: number;
  manual: number;
  placa: number
  orcamentos: number
  
}

interface StoreFilter {
  nomeFantasia: string;
  grupo: string;
  data: any;
  status: string;
}



export default defineComponent({
  name: "dashboardGerencia",
  setup() {
    const isLoadingAttendance: Ref<boolean> = ref(true);
    const loadingApexAtendimentos: Ref<boolean> = ref(true);
    const isLoadingClientes: Ref<boolean> = ref(true);
    const loadingConsulta: Ref<boolean> = ref(true);
    const openDropdown: Ref<boolean> = ref(true);
    const updateChart: Ref<boolean> = ref(true);
    const mesHistLoja = ref("");
    const mesHistOS = ref("");
    const totalLojas: Ref<number> = ref(0);
    const lojasIntegradas: Ref<number> = ref(0);
    const qtdClientes: Ref<number> = ref(0);

    const emitter = useEmitter();


    const value1 = ref("");
    const value2 = ref([]);

    const storeFilter: Ref<StoreFilter> = ref({ nomeFantasia: "", grupo: "", data: "", status: "" });
    
    const traducaoChartsBr = ref([{
      name: 'en',
      options: {
        months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto',
          'Setembro', 'Outubro', 'Novembro', 'Dezembro'
        ],
        shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
        ],
        days: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        toolbar: {
          download: 'Baixar SVG',
          selection: 'Selecionar',
          selectionZoom: 'Selecionar Zoom',
          zoomIn: 'Aumentar',
          zoomOut: 'Diminuir',
          pan: 'Navegação',
          reset: 'Restaurar Zoom',
        }
      }
    }])
    const seriesAtividades: Ref<any> = ref([]);
    const chartOptionsAtividades = ref({
      chart: {
        height: 350,
        type: 'scatter',
        animations: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false
        },
        locales: traducaoChartsBr,
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        // type: 'gradient,'
        opacity: 0.8
      },
      // title: {
      //   text: 'Atividades por loja',
      //   align: 'left',
      //   style: {
      //     fontSize: '16px',
      //     fontWeight: 'bold',
      //     fontFamily: undefined,
      //     color: '#464E5F'
      //   },
      // },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        labels: {
          formatter: function (val, index) {
            return String(val).replace(".", ":") + 'h';
          }
        }
      },
      markers: {
        size: 20
      },
      legend: {
        labels: {
          useSeriesColors: true
        },
        markers: {
          customHTML: [
            function () {
              return ''
            },
            function () {
              return ''
            }
          ]
        }
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function (val) {
            return String(val).replace(".", ":") + 'h';
          }
        }
      }
    })

    // const openModal = id => {
    //   const auxElement = document.querySelector(`#${id}`);
    //   auxModal = new Modal(auxElement);
    //   auxModal.show();
    // };

    function specificFormatData(date: any) {
      let newDate = new Date(date)

      let year = newDate.getFullYear();
      let month = (newDate.getMonth() + 1).toString().padStart(2, '0'); // Adiciona zero à esquerda se necessário
      let day = newDate.getDate().toString().padStart(2, '0'); // Adiciona zero à esquerda se necessário
      // Formatar data
      let formattedDate = `${year}-${month}-${day}`;
      return formattedDate

    }

    

    const dashboardFilter: Ref<dashboardFilter> = ref({
      idLojas: "",
      idGrupos: "",
      data: "",
      regiao: "",
    });

    const contentDashboard: Ref<ContentDashboard> = ref<ContentDashboard>({
      consultas: 0,
      manual: 0,
      placa: 0,    
      orcamentos: 0,
    });

    async function getAttendanceDashboard() {
      isLoadingAttendance.value = true;

      try {
        const { data } = await Api.get("getAttendanceDashboard", { ...dashboardFilter.value });

        const atendimentos = data.novoTotal[0];

        contentDashboard.value.orcamentos = Number(atendimentos.Atendimentos_orçados) + Number(atendimentos.Atendimentos_somente_consulta)
        
      } catch (error) {
        console.log(error);
      } finally {
        isLoadingAttendance.value = false;
      }
    }

    async function getConsultas() {
      loadingConsulta.value = true;

      const dataInicial = dashboardFilter.value.data.split(',')[0]
      const dataFinal = dashboardFilter.value.data.split(',')[1]

      try {
        const { data } = await Api.get("getConsultas", { dataInicial, dataFinal, idLoja: dashboardFilter.value.idLojas});

        contentDashboard.value.consultas = data.total
        contentDashboard.value.placa = data.placa
        contentDashboard.value.manual = data.manual
        
      } catch (error) {
        console.log(error);
      } finally {
        loadingConsulta.value = false;
      }
    }

    async function getClientes(){
      isLoadingClientes.value = true
      try{
        const { clientes } = await Api.get("getArrayClientes", { idLoja: dashboardFilter.value.idLojas, data: dashboardFilter.value.data});
        qtdClientes.value = clientes.length
      }catch(error){
        console.log(error)
      }finally{
        isLoadingClientes.value = false
      }
    }

    async function getDadosAtividades(){
      loadingApexAtendimentos.value = true
      const dataInicial = trataDataDadosAtividades(dashboardFilter.value.data.split(',')[0])
      const dataFinal = trataDataDadosAtividades(dashboardFilter.value.data.split(',')[1])

      try {
        const { dados } = await Api.get("getDadosAtividades", { dataInicial, dataFinal, idLoja: dashboardFilter.value.idLojas});

        seriesAtividades.value = dados.loja.series

      } catch (error) {
        console.log(error);
      } finally{
        loadingApexAtendimentos.value = false
      }
    }

    function trataDataDadosAtividades(data){
      const partes = data.split('-');
      const novaData = partes[2] + '/' + partes[1] + '/' + partes[0];
      return novaData
    }
 
    emitter.on("filter-dashboard-gerencia", async params => {
      dashboardFilter.value = {
        idGrupos: params.dataFilter.grupo,
        idLojas: params.dataFilter.loja,
        data: `${specificFormatData(params.dataFilter.data[0])},${specificFormatData(params.dataFilter.data[1])}`,
        regiao: params.dataFilter.regiao,
      };
    });



    watch(
      () => [dashboardFilter.value.idLojas, dashboardFilter.value.idGrupos, dashboardFilter.value.data, dashboardFilter.value.regiao],
      () => {
        getAttendanceDashboard();
        getDadosAtividades();
        getClientes()
        getConsultas()
      }
    );

    return {
      value1,
      value2,
      getAttendanceDashboard,
      contentDashboard,
      isLoadingAttendance,
      openDropdown,
      dashboardFilter,
      storeFilter,
      console,
      ElConfigProvider,
      ptBr,
      mesHistLoja,
      updateChart,
      mesHistOS,
      totalLojas,
      lojasIntegradas,
      chartOptionsAtividades,
      seriesAtividades,
      loadingApexAtendimentos,
      qtdClientes,
      isLoadingClientes,
      trataDataDadosAtividades,
      loadingConsulta
    };
  },
});
